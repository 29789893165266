<template>
	<v-card flat class="bonus-table" :class="computedClass">
		<QDataTableHeader class="bonus-table__header">
			<template #left>
				<p class="bonus-table__header-summary">
					{{ title }} Bonus:
					<span v-if="!loading && !error">
						<span v-if="totalBonus < 0">-</span>
						<span class="with-currency">{{ formatNumber(Math.abs(totalBonus), '(0,0.00)') }}</span>
					</span>
				</p>
			</template>
			<template #right>
				<v-text-field
					v-model="computedSearch"
					:disabled="loading"
					append-icon="icon-q-search"
					label="Search"
					dense single-line hide-details
					color="#1587E4"
					class="bonus-table__header-search"
					@keyup="maybeSearch"
					@click:append="$emit('search')"
				/>
			</template>
		</QDataTableHeader>

		<v-row>
			<v-col class="d-flex justify-center justify-sm-space-between align-center flex-wrap">
				<span class="mx-4 my-1">{{ formattedDatePeriod }}</span>
				<v-btn
					v-if="error"
					class="error-btn"
					elevation="0"
					color="#1383EA"
					@click="$emit('load-data')"
				>
					<v-icon>fas fa-sync-alt</v-icon>
					<v-divider class="mx-2" color="white" vertical />
					Reload Data
				</v-btn>

				<v-btn
					v-else
					text
					color="#1383EB"
					:disabled="loading || !items?.length"
					@click="$emit('export-results')"
				>
					<v-icon>fas fa-file-export</v-icon>
					<v-divider class="mx-2" vertical />
					Export Results
				</v-btn>
			</v-col>
		</v-row>

		<QExpandableDataTable
			:key="tableKey"
			:headers="visibleHeaders"
			:items="items"
			:page.sync="computedOptions.page"
			:items-per-page.sync="computedOptions.itemsPerPage"
			:sort-by.sync="computedOptions.sortBy"
			:sort-desc.sync="computedOptions.sortDesc"
			:server-items-length="serverItemsLength"
			:expanded.sync="expanded"
			:item-key="itemUniqueKey"
			:loading="loading"
			:hide-default-header="isMobileTableView"
			class="bonus-table__table"
			color="q_leads_1"
			:dense="!isMobileTableView"
			:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
			:show-expand="showExpand"
		>
			<template v-for="h in visibleHeaders" v-slot:[`item.${h.value}`]="{ item, value, index }">
				<div :key="`${h.value}-${index}`" :class="[h.cellClass, !isMobileView && 'nowrap']">
					<slot :name="`item.${h.value}`" v-bind="{ item, value, header: h }">
						{{ formatItem(value, h.value) }}
					</slot>
				</div>
			</template>
			<template v-if="showExpand" v-slot:expand="{ item }">
				<div class="bonus-table__expand-wrapper">
					<v-simple-table v-if="isMobileTableView">
						<tbody>
							<tr v-for="header in expandedHeaders" :key="header.value">
								<td class="text-start cell-title">
									{{ header.text }}
								</td>
								<td class="text-end">
									<slot :name="`item.${header.value}`" v-bind="{ item, value: item[header.value], header }">
										{{ formatItem(item[header.value], header.value) }}
									</slot>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<template v-else>
						<v-simple-table v-if="expandedTable" dense class="bonus-table__line-table">
							<thead>
								<tr>
									<th v-for="h in expandedHeaders" :key="h.value">
										<span>{{ h.text }}</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-for="h in expandedHeaders" :key="h.value">
										<span class="nowrap">
											<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
												{{ formatItem(item[h.value], h.value) }}
											</slot>
										</span>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<div v-else class="bonus-table__line-flex">
							<div v-for="h in expandedHeaders" :key="h.value">
								<span class="cell-title">{{ h.text }}</span>:
								<span class="bonus-table__line-flex-content">
									<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
										{{ formatItem(item[h.value], h.value) }}
									</slot>
								</span>
							</div>
						</div>
					</template>
				</div>
			</template>
		</QExpandableDataTable>

		<v-row v-if="doShowDisclaimer">
			<v-col>
				<p class="bonus-table__disclaimer">{{ disclaimer }}</p>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import BonusReportTableMixin from './BonusReportTableMixin'

export default {
    components: {
        QDataTableHeader,
        QExpandableDataTable,
    },
	mixins: [BonusReportTableMixin],
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';
</style>
