<template>
	<v-card flat class="bonus-table" :class="computedClass">
		<QDataTableHeader class="bonus-table__header">
			<template #left>
				<p class="bonus-table__header-summary">
					{{ title }} Bonus:
					<span v-if="!loading && !error">
						<span v-if="totalBonus < 0">-</span>
						<span class="with-currency">{{ formatNumber(Math.abs(totalBonus), '(0,0.00)') }}</span>
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn class="ml-2" icon color="#1587E4" v-bind="attrs" v-on="on">
									<v-icon>icon-info-circle-q</v-icon>
								</v-btn>
							</template>
							<p class="mb-0" style="max-width:18rem;">{{ totalBonusDefinition }}</p>
						</v-tooltip>
					</span>
				</p>
			</template>
			<template #right>
				<v-text-field
					v-model="computedSearch"
					:disabled="loading"
					append-icon="icon-q-search"
					label="Search"
					dense single-line hide-details
					color="#1587E4"
					class="bonus-table__header-search"
					@keyup="maybeSearch"
					@click:append="$emit('search')"
				/>
			</template>
		</QDataTableHeader>

		<v-row>
			<v-col class="d-flex justify-center justify-sm-space-between align-center flex-wrap">
				<span class="mx-4 my-1">{{ formattedDatePeriod }}</span>
				<v-btn
					v-if="error"
					class="error-btn"
					elevation="0"
					color="#1383EA"
					@click="$emit('load-data')"
				>
					<v-icon>fas fa-sync-alt</v-icon>
					<v-divider class="mx-2" color="white" vertical />
					Reload Data
				</v-btn>

				<v-btn
					v-else
					text
					color="#1383EB"
					:disabled="loading || !items?.length"
					@click="$emit('export-results')"
				>
					<v-icon>fas fa-file-export</v-icon>
					<v-divider class="mx-2" vertical />
					Export Results
				</v-btn>
			</v-col>
		</v-row>

		<QExpandableDataTable
			:key="tableKey"
			:headers="visibleHeaders"
			:items="items"
			:page.sync="computedOptions.page"
			:items-per-page.sync="computedOptions.itemsPerPage"
			:sort-by.sync="computedOptions.sortBy"
			:sort-desc.sync="computedOptions.sortDesc"
			:server-items-length="serverItemsLength"
			:expanded.sync="expanded"
			:item-key="itemUniqueKey"
			:loading="loading"
			:hide-default-header="isMobileTableView"
			class="bonus-table__table"
			color="q_leads_1"
			:dense="!isMobileTableView"
			:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
			show-expand
			@item-expanded="getBonusDetails"
		>
			<template v-if="isMobileTableView" v-slot:before-expand="{ item }">
				<v-btn v-if="doShowBonusDetailsBtn(item.ID)" class="ml-2" color="#1383EA" icon @click.stop="getBonusDetails({ item }, true)">
					<v-icon>fas fa-angle-double-down</v-icon>
				</v-btn>
			</template>

			<template v-for="h in visibleHeaders" v-slot:[`item.${h.value}`]="{ item, value, index }">
				<div :key="`${h.value}-${index}`" :class="[h.cellClass, !isMobileView && 'nowrap']">
					<slot :name="`item.${h.value}`" v-bind="{ item, value, header: h }">
						{{ formatItem(value, h.value) }}
					</slot>
				</div>
			</template>

			<template v-slot:expand="{ item }">
				<div v-if="showExpand" class="bonus-table__expand-wrapper">
					<v-simple-table v-if="isMobileTableView">
						<tbody>
							<tr v-for="header in expandedHeaders" :key="header.value">
								<td class="text-start cell-title">
									{{ header.text }}
								</td>
								<td class="text-end">
									<slot :name="`item.${header.value}`" v-bind="{ item, value: item[header.value], header }">
										{{ formatItem(item[header.value], header.value) }}
									</slot>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<template v-else>
						<v-simple-table v-if="expandedTable" dense class="bonus-table__line-table">
							<thead>
								<tr>
									<th v-for="h in expandedHeaders" :key="h.value">
										<span>{{ h.text }}</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-for="h in expandedHeaders" :key="h.value">
										<span class="nowrap">
											<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
												{{ formatItem(item[h.value], h.value) }}
											</slot>
										</span>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<div v-else class="bonus-table__line-flex">
							<div v-for="h in expandedHeaders" :key="h.value">
								<span class="cell-title">{{ h.text }}</span>:
								<span class="bonus-table__line-flex-content">
									<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
										{{ formatItem(item[h.value], h.value) }}
									</slot>
								</span>
							</div>
						</div>
					</template>
				</div>
				<div v-if="item._details.loading || item._details.error" class="bonus-table__expand-wrapper">
					<v-progress-linear v-if="item._details.loading" indeterminate />
					<div v-else-if="item._details.error" class="d-flex justify-center">
						<v-alert type="error" text max-width="800px">
							<p class="mb-0">Oops! There was a problem loading Bird-Dog Bonus details. Please try reloading the page.</p>
						</v-alert>
					</div>
				</div>
				<BirdDogBonusReportBreakdownTable
					v-if="item._details.data"
					:item="item"
					:is-mobile-table-view="isMobileTableView"
					:has-admin-permissions="hasAdminPermissions"
				/>
			</template>
		</QExpandableDataTable>

		<v-row>
			<v-col>
				<p class="bonus-table__disclaimer">{{ disclaimer }}</p>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import BirdDogBonusReportBreakdownTable from './BirdDogBonusReportBreakdownTable.vue'
import BonusReportTableMixin from './BonusReportTableMixin'

import { getBirdDogBonusDetails } from '../api/index'
import { BirdDogRole } from '../config/bonusConfigs'

export default {
    components: {
        QDataTableHeader,
        QExpandableDataTable,
		BirdDogBonusReportBreakdownTable,
    },
	mixins: [BonusReportTableMixin],
    data () {
        return {
			totalBonusDefinition: 'This is your personal bonus amount only and does not include your downline\'s bonus amount.',
		}
    },
    computed: {
		hasAdminPermissions () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
    },
    methods: {
		async getBonusDetails ({ item, value }, isForceLoad) {
			await this.getLevels()
			const bonusItem = this.items.find(({ ID }) => ID === item.ID)
			if (bonusItem._details.loading || bonusItem._details.data) { return }
			if (!isForceLoad && (!value || this.isMobileTableView)) { return }
			bonusItem._details.loading = true
			bonusItem._details.error = false
			// check if the parent id is set... if not... use the ID of the item. 
			const parentID = item.BirdDog_ParentBonusID !== null ? item.BirdDog_ParentBonusID : item.ID;
			const res = await getBirdDogBonusDetails(parentID)
			if (res.error) {
				bonusItem._details.loading = false
				bonusItem._details.error = true
				this.showError(`Oops! There was a problem loading Bird-Dog Bonus Details.<br>`, res.message)
			} else {
				bonusItem._details.data = res.data
				bonusItem._details.loading = false
			}
		},
		async getLevels () {
			try {
				await QuilityAPI.getAgentManagement_Levels()
			} catch (err) {
				console.error(err)
				// this.showError('Oops! There was a problem loading Leadership Levels.<br>', err.message)
			}
        },
		doShowBonusDetailsBtn (id) {
			return !!this.expanded.find(({ ID }) => ID === id)
		},
		filterTableHeaders (item) {
			if (!item.disabledView?.length) { return true }
			const role = this.hasAdminPermissions ? BirdDogRole.admin : BirdDogRole.agent
			return !item.disabledView.includes(role)
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bonus-table {
	--width-table-expand-column: 3rem;

	&--tablet,
	&--mobile {
		--width-table-expand-column: 5rem;
	}

	&__table {
		::v-deep {
			thead tr th {
				&.q-expand-icon {
					width: var(--width-table-expand-column);
				}
			}
		}
	}
}
</style>
